'use strict';

// Constructor
var QuantitySelector = function() {
    var leftArrow = $('.quantity-selector__left-arrow');
    var rightArrow = $('.quantity-selector__right-arrow');
    var quantity = $('.quantity-selector__value');
    var totalHtml =  $('.carro-de-compras__total__number');
    var price = $('.carro-de-compras__item__price');
    var cartButton = $('.producto__hero__button');
    var currentHref = cartButton.attr('href');

    var updateTotal = function() {
        var priceValue = price.data('price');
        var amount = quantity.data('quantity');        

        totalHtml.html('$' + (priceValue * amount));
    }

    var updateButton = function(qty) {        
        var newHref = currentHref + '&qty=' + qty;

        cartButton.attr('href', newHref);
    }

    leftArrow.on('click', function(e) {
        e.preventDefault();
        var initialValue = parseInt(quantity.html());

        if (initialValue === 0) {
            return false;
        } else {
            var finalValue = (initialValue - 1);
            quantity.data('quantity', finalValue);
            quantity.html(finalValue);

            if (totalHtml.length > 0) {
                updateTotal();
            } else {
                updateButton(finalValue);
            }
        };
    });

    rightArrow.on('click', function(e) {
        e.preventDefault();
        var initialValue = parseInt(quantity.html());

        if (initialValue === 10) {
            return false;
        } else {
            var finalValue = (initialValue + 1);
            quantity.data('quantity', finalValue);
            quantity.html(finalValue);

            if (totalHtml.length > 0) {
                updateTotal();
            } else {
                updateButton(finalValue);
            }
        };
    });


};

module.exports = QuantitySelector;
