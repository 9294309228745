'use strict';

var mercadopago = require ('mercadopago');

var Cart = function() {

  var context = $('.carro-de-compras');

  if (context.length > 0) {
    var paramsInUrl = new URLSearchParams(window.location.search);
    var quantity = $('.quantity-selector__value');
    var totalHtml =  $('.carro-de-compras__total__number');
    var price = $('.carro-de-compras__item__price');

    var updateTotal = function() {
        var priceValue = price.data('price');
        var amount = quantity.data('quantity');

        console.log(priceValue * amount);

        totalHtml.html('$' + (priceValue * amount));
    }

    var wines = [
      {
        price: 250,
        name: 'malbec',
        image: 'malbec-1',
        btn: 'https://www.mercadopago.com.ar/checkout/v1/redirect?preference-id=369344879-ccd095c6-a3e3-4ad7-84ec-689da3728867'
      },
      {
        price: 400,
        name: 'malbec-reserva',
        image: 'malbec-2',
        btn: 'https://www.mercadopago.com.ar/checkout/v1/redirect?preference-id=369344879-cbebec5d-085f-452f-af80-757a809942f2'
      },
      {
        price: 280,
        name: 'pasita',
        image: 'pasita',
        btn: 'https://www.mercadopago.com.ar/checkout/v1/redirect?preference-id=369344879-e8f977e3-6757-49ed-b844-8ebfa7b3b23d'
      },
      {
        price: 240,
        name: 'blanc',
        image: 'blanc',
        btn: 'https://www.mercadopago.com.ar/checkout/v1/redirect?preference-id=369344879-050e547f-ed87-4321-988f-0decd29764ec'
      }
    ];

    if (paramsInUrl.has('wine')) {
      
      var currentWine = wines.filter(function(wine){
        return wine.name === paramsInUrl.get('wine');
      })[0];
      
      var winePriceTag = $('.carro-de-compras__item__price');
      var wineNameTag = $('.carro-de-compras__item__title');
      var wineImage = $('.carro-de-compras__item__image img');
      
      winePriceTag.data('price', currentWine.price);
      winePriceTag.html('$' + currentWine.price);
      wineNameTag.html(currentWine.name);
      wineImage.attr('src', '/images/home/' + currentWine.image + '.png');

      if (paramsInUrl.has('qty')) {
        quantity.data('quantity', paramsInUrl.get('qty'));
        quantity.html(paramsInUrl.get('qty'));
  
        updateTotal();
      }


      var checkoutBtn = $('.js-checkout');
      var checkoutForm = $('.carro-de-compras__pedido');
      var checkoutCancel = $('.carro-de-compras__pedido__button--cancel');
      var checkoutPayment = $('.carro-de-compras__pedido__button--pay');
      var amount = $('.quantity-selector__value').data('quantity');

      checkoutBtn.on('click', function(e) {
        e.preventDefault();
        checkoutForm.addClass('-active');
      });

      checkoutCancel.on('click', function(e) {
        e.preventDefault();
        checkoutForm.removeClass('-active');
      });

      //checkoutPayment.attr('href', currentWine.btn);


      checkoutPayment.on('click', function(e) {
        e.preventDefault();

        var product = {
          producto: currentWine.name,
          cantidad: parseInt(amount),
          precio: currentWine.price
        }

        processPayment(product);
      });
    }

    function processPayment(item) {
      console.log(item);

      $.ajax({
        type: "POST",
        url: '/mercadopago.php',
        crossDomain: true,
        data: {            
          producto: item.producto,
          cantidad: item.cantidad,
          precio: item.precio
        }
      }).done(function(response) {
        console.log('success AJAX', response);
        window.open(response, '_blank');
      }).fail(function(response) {
        console.log('fail', response);
      });
    }
  }  
}

module.exports = Cart;